import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import { Box, Flex } from 'src/components/FlexBox';
import getComponentByType from 'src/utils/getComponentByType';

export default ({ modules }) => {
  const renderedModules = modules.map((props) => {
    const Module = getComponentByType(props.__typename);
    return (
      <Box
        css={css({
          overflow: 'hidden',
          transform: 'translateZ(0)',
          borderRadius: 2,
          flexDirection: 'column',
        })}
      >
        <Module {...props} />
      </Box>
    );
  });

  return (
    <Flex
      flexDirection="column"
      gy={4}
      css={css({
        mx: 'auto',
        px: 4,
        maxWidth: 'pageMaxWidth',
        width: '100%',
      })}
    >
      {renderedModules}
    </Flex>
  );
};

export const query = graphql`
  fragment CardStackModuleFragment on ContentfulCardStackModule {
    modules {
      ... on ContentfulCustomModule {
        ...CustomModuleFragment
      }
      ... on ContentfulNewTwoUpModule {
        ...NewTwoUpModuleFragment
      }
    }
  }
`;
